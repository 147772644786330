import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// export const handleGeneratePDF = (elementId, fileName = "TankSoundingReport.pdf") => {
  
//   const table = document.getElementById(elementId);

//   html2canvas(table, { scale: 2 }).then((canvas) => {
//     const imgData = canvas.toDataURL("image/png");
//     const pdf = new jsPDF("p", "mm", "a4");

//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();

//     const imgWidth = pdfWidth - 20; // 10mm margin on each side
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;

//     let yPosition = 10; // Start at 10mm from top

//     // Add the first page
//     pdf.addImage(imgData, "PNG", 10, yPosition, imgWidth, imgHeight);

//     // Split content into pages if it exceeds one page
//     if (imgHeight > pdfHeight - 20) {
//       let remainingHeight = imgHeight - (pdfHeight - 20);

//       while (remainingHeight > 0) {
//         pdf.addPage();
//         yPosition = 10;
//         pdf.addImage(
//           imgData,
//           "PNG",
//           10,
//           yPosition - (imgHeight - remainingHeight),
//           imgWidth,
//           imgHeight
//         );
//         remainingHeight -= pdfHeight - 20;
//       }
//     }

//     pdf.save(fileName);
//   });
// };
// export const handleGeneratePDF = (elementId, fileName, scale) => {
//   const table = document.getElementById(elementId);

//   html2canvas(table, {
//     scale: 1.5, // Reduce scale for smaller file size
//     useCORS: true, // Enable cross-origin images if any
//   }).then((canvas) => {
//     const imgData = canvas.toDataURL("image/png");
//     const pdf = new jsPDF("p", "mm", "a4");

//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();

//     // Calculate image dimensions while maintaining aspect ratio
//     const imgWidth = pdfWidth - scale; // 10mm margins on each side
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;

//     // Ensure the image height does not exceed the PDF page height
//     if (imgHeight > pdfHeight - scale) {
//       const scaleFactor = (pdfHeight - scale) / imgHeight;
//       const adjustedImgWidth = imgWidth * scaleFactor;
//       const adjustedImgHeight = imgHeight * scaleFactor;

//       pdf.addImage(imgData, "PNG", 10, 10, adjustedImgWidth, adjustedImgHeight);
//     } else {
//       pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
//     }

//     pdf.save(fileName);
//   });
// };


export const handleGeneratePDF = (elementId, fileName, scale=1) => {
  const table = document.getElementById(elementId);

  html2canvas(table, {
    scale: 2, // Higher scale for better quality
    useCORS: true,
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/jpeg", scale); // JPEG format with 80% quality
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate the aspect ratio of the canvas
    const imgWidth = pdfWidth * scale; // Use 80% of A4 width
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Check if image height exceeds the page height
    if (imgHeight > pdfHeight - 20) {
      const scaleFactor = (pdfHeight - 20) / imgHeight;
      const adjustedImgWidth = imgWidth * scaleFactor;
      const adjustedImgHeight = imgHeight * scaleFactor;

      pdf.addImage(
        imgData,
        "JPEG",
        (pdfWidth - adjustedImgWidth) / 2, // Center horizontally
        10, // Top margin
        adjustedImgWidth,
        adjustedImgHeight
      );
    } else {
      pdf.addImage(
        imgData,
        "JPEG",
        (pdfWidth - imgWidth) / 2, // Center horizontally
        10, // Top margin
        imgWidth,
        imgHeight
      );
    }

    pdf.save(fileName);
  });
};



export const calculateVolume = (sounding, sideData) => {
  if (!sounding || !sideData || sideData.length < 1) return null;

  const numericSounding = parseFloat(sounding);

  // Ensure the sounding is within the range of the tank data
  if (numericSounding < sideData[0].sounding || numericSounding > sideData[sideData.length - 1].sounding) {
    return "Out of range"; // Out of range
  }

  // Find the interval for interpolation
  for (let i = 0; i < sideData.length - 1; i++) {
    const lower = sideData[i];
    const upper = sideData[i + 1];

    if (numericSounding >= lower.sounding && numericSounding <= upper.sounding) {
      // Linear interpolation formula
      const volume =
        lower.volume +
        ((upper.volume - lower.volume) * (numericSounding - lower.sounding)) /
          (upper.sounding - lower.sounding);
      return volume.toFixed(3); // Return volume rounded to three decimal places
    }
  }

  return null;
};

